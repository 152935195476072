'use client';

import {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import NextImage from 'next/image';
import Link from 'next/link';
import logo from '@assets/common/logo.svg';
import { Image } from '@nextui-org/react';
import { AnimationScope, motion, useAnimate } from 'framer-motion';
import { FiArrowUpRight, FiMenu } from 'react-icons/fi';
import useMeasure from 'react-use-measure';

const Header = () => {
  return (
    <section
      className="fixed left-0 right-0 top-0 z-50 w-full overflow-hidden bg-black"
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='%23171717'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e")`,
      }}
    >
      <GlassNavigation />

      <span className="absolute -top-[600px] left-[50%] h-[800px] w-4/5 max-w-3xl -translate-x-[50%] rounded bg-neutral-900" />
    </section>
  );
};

const GlassNavigation = () => {
  const [hovered, setHovered] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const [scope, animate] = useAnimate();
  const navRef = useRef<HTMLDivElement | null>(null);

  const handleMouseMove = ({ offsetX, offsetY, target }: MouseEvent) => {
    // @ts-ignore
    const isNavElement = [...target.classList].includes('glass-nav');

    if (isNavElement) {
      setHovered(true);

      const top = offsetY + 'px';
      const left = offsetX + 'px';

      animate(scope.current, { top, left }, { duration: 0 });
    } else {
      setHovered(false);
    }
  };

  useEffect(() => {
    navRef.current?.addEventListener('mousemove', handleMouseMove);

    return () =>
      navRef.current?.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <nav
      ref={navRef}
      onMouseLeave={() => setHovered(false)}
      style={{
        cursor: hovered ? 'none' : 'auto',
      }}
      className="glass-nav fixed left-0 right-0 top-0 z-10 mx-auto max-w-7xl overflow-hidden border-[1px] border-white/10 bg-gradient-to-br from-white/20 to-white/5 backdrop-blur md:left-6 md:right-6 md:top-6 md:rounded-2xl"
    >
      <div className="glass-nav flex items-center justify-between px-5 py-5">
        <Cursor hovered={hovered} scope={scope} />

        <Logo />

        <div>
          <Links />
          <Buttons setMenuOpen={setMenuOpen} />
        </div>
      </div>

      <MobileMenu menuOpen={menuOpen} />
    </nav>
  );
};

const Cursor = ({
  hovered,
  scope,
}: {
  hovered: boolean;
  scope: AnimationScope<any>;
}) => {
  return (
    <motion.span
      initial={false}
      animate={{
        opacity: hovered ? 1 : 0,
        transform: `scale(${
          hovered ? 1 : 0
        }) translateX(-50%) translateY(-50%)`,
      }}
      transition={{ duration: 0.15 }}
      ref={scope}
      className="pointer-events-none absolute z-0 grid h-[50px] w-[50px] origin-[0px_0px] place-content-center rounded-full bg-gradient-to-br from-primary from-40% to-primary-400 text-2xl"
    >
      <FiArrowUpRight className="text-white" />
    </motion.span>
  );
};

const Logo = () => (
  <span className="pointer-events-none relative z-10 text-4xl font-black text-white mix-blend-overlay w-[90px] h-[40px]">
    <Image
      src={logo.src}
      alt="logo"
      as={NextImage}
      fill
      draggable={false}
      removeWrapper
      className="object-cover"
    />
  </span>
);

const Links = () => (
  <div className="hidden items-center gap-2 md:flex">
    <GlassLink link="#about-us" text="About Us" />
    <GlassLink link="#our-services" text="Our Services" />
    <GlassLink link="#contact" text="Contact" />
  </div>
);

const GlassLink = ({ text, link }: { text: string; link: string }) => {
  return (
    <Link
      href={link}
      className="group relative scale-100 overflow-hidden rounded-lg px-4 py-2 transition-transform hover:scale-105 active:scale-95"
    >
      <span className="relative z-10 text-white/90 transition-colors group-hover:text-white">
        {text}
      </span>
      <span className="absolute inset-0 z-0 bg-gradient-to-br from-white/20 to-white/5 opacity-0 transition-opacity group-hover:opacity-100" />
    </Link>
  );
};

const TextLink = ({ text, link }: { text: string; link: string }) => {
  return (
    <Link
      href={link}
      className="text-white/90 transition-colors hover:text-white"
    >
      {text}
    </Link>
  );
};

const Buttons = ({
  setMenuOpen,
}: {
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}) => (
  <div className="flex items-center gap-4">
    {/* <div className="hidden md:block">
      <SignInButton />
    </div>

    <button className="relative scale-100 overflow-hidden rounded-lg bg-gradient-to-br from-indigo-600 from-40% to-indigo-400 px-4 py-2 font-medium text-white transition-transform hover:scale-105 active:scale-95">
      Try free
    </button> */}

    <button
      onClick={() => setMenuOpen((pv) => !pv)}
      className="ml-2 block scale-100 text-3xl text-white/90 transition-all hover:scale-105 hover:text-white active:scale-95 md:hidden"
    >
      <FiMenu />
    </button>
  </div>
);

const MobileMenu = ({ menuOpen }: { menuOpen: boolean }) => {
  const [ref, { height }] = useMeasure();
  return (
    <motion.div
      initial={false}
      animate={{
        height: menuOpen ? height : '0px',
      }}
      className="block overflow-hidden md:hidden"
    >
      <div ref={ref} className="px-4 pb-4">
        <div className="flex flex-col items-center gap-4 justify-start">
          <TextLink link="#about-us" text="About Us" />
          <TextLink link="#our-services" text="Our Services" />
          <TextLink link="#contact" text="Contact" />
        </div>
      </div>
    </motion.div>
  );
};

export default memo(Header);
